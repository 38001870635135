import React from 'react';

function Divider({indent = 32, color="#C5C6D0"}) {
    return (
        <div
            style={{
                alignSelf: "stretch",
                height: 17,
                paddingLeft: indent,
                paddingRight: indent,
                paddingTop: 8,
                paddingBottom: 8,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                display: "flex"
            }}
        >
            <div
                style={{
                    alignSelf: "stretch",
                    height: 0,
                    borderBottom: "1px "+color+" solid"
                }}
            />
        </div>
    );
}

export default Divider;