import React from 'react';

function RegistrationPrice({price}) {
    return (<div
        style={{
            flexDirection: "column",
            alignSelf: "stretch",
            paddingLeft: 8,
            paddingRight: 8,
            justifyContent: "center",
            alignItems: "center",
            gap: 4,
            display: "inline-flex"
        }}
    >

        <div
            className="price-div"
            style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            }}
        >
            <div
                className="current-price"
                style={{
                    color: "#1B1B1F",
                    fontSize: 28,
                    fontFamily: "Satoshi",
                    fontWeight: 900,
                    letterSpacing: "0.10px",
                    wordWrap: "break-word"
                }}
            >
                {'+' + price['registration'].toFixed(2) + ' ' + price['currency']}
            </div>
            <div
                className="per-comp"
                style={{
                    color: "#1B1B1F",
                    fontSize: 15,
                    fontFamily: "Satoshi",
                    fontWeight: 700,
                    lineHeight: "12.99px",
                    letterSpacing: "0.10px",
                    wordWrap: "break-word"
                }}
            >
                {'per competitor'}
            </div>
        </div>
        <div
            style={{
                color: "#1B1B1F",
                fontSize: 18,
                fontFamily: "Satoshi",
                fontWeight: 500,
                letterSpacing: "0.10px",
                wordWrap: "break-word",
                textAlign: "center"
            }}
        >
            In-app registration
        </div>
    </div>);
}

export default RegistrationPrice;