import React from 'react';

function ContactUsButton() {
    return (
        <div
            style={{
                alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
                display: "inline-flex"
            }}
        >
            <a
                style={{
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingTop: 8,
                    paddingBottom: 8,
                    textAlign: "center",
                    color: "white",
                    fontSize: 20,
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    lineHeight: "32px",
                    wordWrap: "break-word",
                    textDecoration: "none"
                }}
                href="mailto:contact@judomobile.app"
            >
                Contact us
            </a>
        </div>
    );
}

export default ContactUsButton;