import React, {useEffect, useState} from 'react';
import CheckItem from "./CheckItem";
import Loading from "../../core/Loading";
import Price from "./Price";
import NoPrice from "./NoPrice";
import ContactUsButton from "./ContactUsButton";
import CountrySelect from "./CountrySelect";
import Divider from "./Divider";

const managerCountries = [
    ['AU', 'Australia'],
    ['AT', 'Austria'],
    ['AZ', 'Azerbaijan'],
    ['BE', 'Belgium'],
    ['BA', 'Bosnia and Herzegovina'],
    ['HR', 'Croatia'],
    ['CY', 'Cyprus'],
    ['CZ', 'Czechia'],
    ['EE', 'Estonia'],
    ['FI', 'Finland'],
    ['FR', 'France'],
    ['GE', 'Georgia'],
    ['DE', 'Germany'],
    ['GR', 'Greece'],
    ['HU', 'Hungary'],
    ['IE', 'Ireland'],
    ['IL', 'Israel'],
    ['IT', 'Italy'],
    ['LV', 'Latvia'],
    ['LB', 'Lebanon'],
    ['LT', 'Lithuania'],
    ['LU', 'Luxembourg'],
    ['MO', 'Macao'],
    ['MG', 'Madagascar'],
    ['MT', 'Malta'],
    ['ME', 'Montenegro'],
    ['NL', 'Netherlands'],
    ['PL', 'Poland'],
    ['PT', 'Portugal'],
    ['RO', 'Romania'],
    ['SA', 'Saudi Arabia'],
    ['RS', 'Serbia'],
    ['SK', 'Slovakia'],
    ['SI', 'Slovenia'],
    ['ES', 'Spain'],
    ['SE', 'Sweden'],
    ['CH', 'Switzerland'],
    ['TR', 'Turkey'],
    ['GB', 'United Kingdom'],
    ['US', 'United States'],
]

function ManagerPricing({countryCode, isDefaultCountryCode}) {
    const [price, setPrice] = useState(null);
    const [noPrice, setNoPrice] = useState(false);

    useEffect(() => {
        const analytics = async () => {
            await fetch('https://server307297.nazwa.pl/jm_results/analytics.php?c=' + countryCode)
                .catch(error => {
                    console.error("Error fetching analytics data:", error);
                });
        }

        const downloadPricing = async () => {
            const response = await fetch('/pricing.json?q=' + Date.now());
            return await response.json();
        }

        const loadPricing = async () => {
            if (!isDefaultCountryCode) {
                await analytics();
            }

            const pricing = await downloadPricing();

            if (countryCode) {
                const countryPrice = pricing[countryCode] || pricing['manager'];
                if (countryPrice) {
                    setPrice(countryPrice);
                    setNoPrice(false);
                } else {
                    console.error("Country code not found in pricing data");
                    setPrice(null);
                    setNoPrice(true);
                }
            }
        }

        loadPricing();
    }, [countryCode, isDefaultCountryCode]);

    const loading = price === null && !noPrice;

    return (<div
        style={{
            paddingBottom: 8,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 8,
            display: "inline-flex"
        }}
    >
        {loading && <Loading/>}
        {(!loading && !noPrice) && <Price price={price}/>}
        {(!loading && noPrice) && <NoPrice/>}
        <div
            style={{
                alignSelf: "stretch",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                display: "flex",
            }}
        >
            <Divider indent={32}/>
            <CheckItem title="Real-time results for all users"/>
            <CheckItem title="In-app live streams"/>
            <CheckItem title="Replay contests anytime"/>
            <CheckItem title="Notifications about upcoming contests"/>
            <CheckItem title="Access to tournament statistics"/>
            <div
                style={{
                    paddingTop: 8,
                    paddingBottom: 8,
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 10,
                    display: "flex"
                }}
            >
                <div
                    style={{
                        background: "#0258C9",
                        borderRadius: 100,
                        overflow: "hidden",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                        display: "flex"
                    }}
                >
                    <ContactUsButton/>
                </div>
            </div>
        </div>
        <div
            className="country-note"
            style={{
                paddingRight: 32,
                paddingLeft: 32,
                paddingBottom: 8,
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 4,
                display: "inline-flex"
            }}
        >
            <div
                style={{
                    letterSpacing: "0.10px",
                    wordWrap: "break-word",
                    fontWeight: 400,
                    textAlign: "center",
                }}
            >
                Price for tournaments organized using
                <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; JudoManager software in
                <CountrySelect countries={managerCountries} otherCountry={true} countryCode={countryCode}/>
            </div>
        </div>
    </div>);
}

export default ManagerPricing;