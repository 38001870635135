import React from 'react';

const CountrySelect = ({countries, countryCode, otherCountry = false}) => {
    const handleChange = (event) => {
        const code = event.target.value;
        const url = new URL(window.location);
        if (code) {
            url.searchParams.set('c', code);
        } else {
            url.searchParams.delete('c');
        }
        window.history.pushState({}, '', url);

        // Force reload
        window.location.reload();
    };

    if (otherCountry) {
        countries = [['-', 'Other country']].concat(countries);
        // Fallback to 'Other country' if the country code is not in the list
        if (countries.findIndex(([code, _]) => code === countryCode) === -1) {
            countryCode = '-';
        }
    }

    return (
        <select
            className="country-select"
            style={{
                color: "#0258C9",
                cursor: "pointer",
                appearance: "none",
                border: "none",
                background: "none",
                paddingLeft: "4px",
                width: "100px",
                display: "inline-block",
            }}
            onChange={handleChange}
            value={countryCode ?? '-'}
        >
            {countries.map(([code, name]) => (
                <option key={code} value={code}>{name}</option>
            ))}
        </select>
    );
};

export default CountrySelect;
