import React, {useEffect, useState} from 'react';
import CountrySelect from "./CountrySelect";
import CheckItem from "./CheckItem";
import Loading from "../../core/Loading";
import Price from "./Price";
import NoPrice from "./NoPrice";
import ContactUsButton from "./ContactUsButton";
import Divider from "./Divider";
import RegistrationPrice from "./RegistrationPrice";
import VerticalDivider from "./VerticalDivider";

const shiaiCountries = [
    ['PL', 'Poland'],
    ['SK', 'Slovakia'],
]

function ShiaiPricing({countryCode, isDefaultCountryCode}) {
    const [price, setPrice] = useState(null);
    const [noPrice, setNoPrice] = useState(false);

    useEffect(() => {
        const analytics = async () => {
            await fetch('https://server307297.nazwa.pl/jm_results/analytics.php?c=' + countryCode)
                .catch(error => {
                    console.error("Error fetching analytics data:", error);
                });
        }

        const downloadPricing = async () => {
            const response = await fetch('/pricing.json?q=' + Date.now());
            return await response.json();
        }

        const loadPricing = async () => {
            if (!isDefaultCountryCode) {
                await analytics();
            }

            const pricing = await downloadPricing();

            if (countryCode) {
                const countryPrice = pricing[countryCode];
                if (countryPrice) {
                    setPrice(countryPrice);
                    setNoPrice(false);
                } else {
                    console.error("Country code not found in pricing data");
                    setPrice(null);
                    setNoPrice(true);
                }
            }
        }

        loadPricing();
    }, [countryCode, isDefaultCountryCode]);

    const loading = price === null && !noPrice;

    const [scaleFactor, setScaleFactor] = useState(Math.min(window.innerWidth / 500, 1));

    useEffect(() => {
        const handleResize = () => {
            setScaleFactor(Math.min(window.innerWidth / 500, 1));
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (<div
        style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 4,
            display: "inline-flex"
        }}
    >
        <div
            style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
                display: "inline-flex",
                width: !loading && !noPrice && price['registration'] ? 500 : null,
                transform: !loading && !noPrice && price['registration'] ? `scale(${scaleFactor})` : null,
            }}
        >
            {loading && <Loading/>}
            {(!loading && !noPrice) && <Price price={price}/>}
            {(!loading && noPrice) && <NoPrice/>}
            {(!loading && !noPrice && price['registration']) && <VerticalDivider indent={32}/>}
            {(!loading && !noPrice && price['registration']) && <RegistrationPrice price={price}/>}
        </div>

        <div
            style={{
                // alignSelf: "stretch",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                display: "flex",
                maxWidth: "90vw",
            }}
        >
            <Divider indent={32}/>
            <CheckItem title="Real-time results for all users"/>
            <CheckItem title="Notifications about upcoming contests"/>
            <CheckItem title="In-app live streams"/>
            <CheckItem title="Direct support and fast replies"/>
            <div
                style={{
                    paddingTop: 8,
                    paddingBottom: 4,
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 10,
                    display: "flex"
                }}
            >
                <div
                    style={{
                        background: "#0258C9",
                        borderRadius: 100,
                        overflow: "hidden",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                        display: "flex"
                    }}
                >
                    <ContactUsButton/>
                </div>
            </div>
        </div>
        <div
            className="country-note"
            style={{
                paddingRight: 32,
                paddingLeft: 32,
                paddingBottom: 4,
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 4,
                display: "inline-flex"
            }}
        >
            <div
                style={{
                    letterSpacing: "0.10px",
                    wordWrap: "break-word",
                    textAlign: "center",
                    fontWeight: 300,
                }}
            >
                Price for tournaments organized using
                <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; JudoShiai software in
                <CountrySelect countries={shiaiCountries} countryCode={countryCode}/>
            </div>
        </div>
    </div>);
}

export default ShiaiPricing;